/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { Icon, Button } from "@atoms";
import { HeadingOutlined } from "@molecules";
import useAllEmail from "@staticQueries/AllEmailQuery";

// 38483  - 1 - General News & Events (Weekly)
// 38489  - 2 - Commuter News (Monthly)
// 38493  - 3 - Bicycle Commuter News (Monthly)
// 113917 - 4 -  Community News (Quarterly)

const NewsletterSignup = ({ listIds, small }) => {
  const emails = useAllEmail();
  // remove uncompressed html
  const preppedEmails = emails.map(email => {
    const pEmail = email;
    delete pEmail.html;
    return pEmail;
  });
  const inputClass =
    "text-body w-full max-w-full rounded-none  border-b-2 border-black bg-transparent p-0.5 pb-1 font-serif";
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      lists: listIds || ["38483", "38489", "38493", "113917"],
    },
  });
  const selectedEmails = watch("lists");

  const toggleList = id => {
    if (!selectedEmails.includes(id)) {
      setValue("lists", [...selectedEmails, id]);
    } else {
      setValue(
        "lists",
        selectedEmails.filter(email => email !== id)
      );
    }
  };

  const onSubmit = async data => {
    setSubmitting(true);
    const res = await fetch("/api/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data, emails: preppedEmails }),
    });
    const json = await res.json();
    if (json.error) {
      setError(json.error);
    } else {
      setSuccess(true);
    }
    setSubmitting(false);
  };

  return (
    <div className="bg-gold w-full">
      {!success && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={clsx("grid grid-cols-1 gap-10", {
            "sm:grid-cols-2": !small,
            "pointer-events-none animate-pulse": submitting,
            "max-w-md": small,
          })}
        >
          <div className="flex flex-col gap-10">
            <fieldset
              className={clsx("grid grid-cols-1 gap-4 ", {
                "sm:grid-cols-2": !small,
              })}
            >
              <label className="grid grid-cols-1 gap-2" htmlFor="firstName">
                <span className="text-xs uppercase">First Name:</span>
                <input
                  type="text"
                  className={clsx(inputClass)}
                  {...register("firstName", {
                    required: true,
                    maxLength: 80,
                  })}
                />
              </label>
              <label className="grid grid-cols-1 gap-2" htmlFor="lastName">
                <span className="text-xs uppercase">Last Name:</span>
                <input
                  type="text"
                  className={clsx(inputClass)}
                  {...register("lastName", {
                    required: true,
                    maxLength: 100,
                  })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label className="grid grid-cols-1 gap-2" htmlFor="email">
                <span className="text-xs uppercase">Work Email:</span>
                <input
                  type="text"
                  className={clsx(inputClass)}
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
              </label>
            </fieldset>
            <fieldset>
              <label className="grid grid-cols-1 gap-2" htmlFor="company">
                <span className="text-xs uppercase">Company Name:</span>
                <input
                  type="text"
                  className={clsx(inputClass)}
                  {...register("company", { required: true })}
                />
              </label>
            </fieldset>
          </div>
          <div className="flex flex-col gap-4">
            <h3 className="uppercase text-sm mb-2 font-bold">
              Select The Newsletters You would like to Subscribe to
            </h3>
            {/* 1 */}
            <label
              htmlFor="38483"
              className="flex cursor-pointer gap-2"
              onClick={() => toggleList("38483")}
            >
              <input
                type="checkbox"
                className="sr-only"
                {...register("lists")}
                value="38483"
              />
              <span
                className={clsx(
                  "border-2 border-white flex-shrink-0 bg-black h-5 w-5 flex items-center justify-center text-white"
                )}
              >
                {selectedEmails.includes("38483") && (
                  <Icon name="check" className="w-2.5 h-2.5" />
                )}
              </span>
              <span className="grid grid-cols-1 gap-1">
                <span className="text-sm block">
                  <b>News & Events (weekly)</b>
                </span>
                <span className="text-xs block font-roboto font-medium">
                  Updates to what’s happening at Stanford Research Park
                </span>
              </span>
            </label>
            <label
              htmlFor="38489"
              className="flex cursor-pointer gap-2"
              onClick={() => toggleList("38489")}
            >
              <input
                type="checkbox"
                className="sr-only"
                {...register("lists")}
                value="38489"
              />
              <span
                className={clsx(
                  "border-2 border-white flex-shrink-0 bg-black h-5 w-5 flex items-center justify-center text-white"
                )}
              >
                {selectedEmails.includes("38489") && (
                  <Icon name="check" className="w-2.5 h-2.5" />
                )}
              </span>
              <span className="grid grid-cols-1 gap-1">
                <span className="text-sm block">
                  <b>Commuter News (monthly)</b>
                </span>
                <span className="text-xs block font-roboto font-medium">
                  The latest news you need to get to and around Stanford
                  Research Park
                </span>
              </span>
            </label>
            <label
              htmlFor="38493"
              className="flex cursor-pointer gap-2"
              onClick={() => toggleList("38493")}
            >
              <input
                type="checkbox"
                className="sr-only"
                {...register("lists")}
                value="38493"
              />
              <span
                className={clsx(
                  "border-2 border-white flex-shrink-0 bg-black h-5 w-5 flex items-center justify-center text-white"
                )}
              >
                {selectedEmails.includes("38493") && (
                  <Icon name="check" className="w-2.5 h-2.5" />
                )}
              </span>
              <span className="grid grid-cols-1 gap-1">
                <span className="text-sm block">
                  <b>Bicycle Commuter News (monthly)</b>
                </span>
                <span className="text-xs block font-roboto font-medium">
                  Your guide to getting around on two wheels
                </span>
              </span>
            </label>
            <label
              htmlFor="113917"
              className="flex cursor-pointer gap-2"
              onClick={() => toggleList("113917")}
            >
              <input
                type="checkbox"
                className="sr-only"
                {...register("lists")}
                value="113917"
              />
              <span
                className={clsx(
                  "border-2 border-white flex-shrink-0 bg-black h-5 w-5 flex items-center justify-center text-white"
                )}
              >
                {selectedEmails.includes("113917") && (
                  <Icon name="check" className="w-2.5 h-2.5" />
                )}
              </span>
              <span className="grid grid-cols-1 gap-1">
                <span className="text-sm block">
                  <b>Community News (monthly)</b>
                </span>
                <span className="text-xs block font-roboto font-medium">
                  Stanford Research Park news and events for the Palo Alto
                  community
                </span>
              </span>
            </label>
          </div>
          <div className={clsx({ "sm:col-span-2": !small })}>
            <Button size="sm" icon="arrow" type="submit">
              Subscribe
            </Button>
          </div>
        </form>
      )}
      {success && (
        <div className="py-10 w-full">
          <HeadingOutlined text="Success!" variant="h1" color="black" />
          <hr className="border-black my-5" />
          <p>
            You have subscribed to the following Newsletters. Look for the
            latest edition in your inbox:
          </p>
          <ul className="mt-4 flex flex-col gap-2">
            {selectedEmails.includes("38483") && (
              <li className="flex gap-2">
                <Icon
                  name="check"
                  className="w-6 h-6 rounded-full p-1 border-2 border-black "
                />
                <span>General News & Events</span>
              </li>
            )}
            {selectedEmails.includes("38489") && (
              <li className="flex gap-2">
                <Icon
                  name="check"
                  className="w-6 h-6 rounded-full p-1 border-2 border-black "
                />
                <span>Commuter News</span>
              </li>
            )}
            {selectedEmails.includes("38493") && (
              <li className="flex gap-2">
                <Icon
                  name="check"
                  className="w-6 h-6 rounded-full p-1 border-2 border-black "
                />
                <span>Bicycle Commuter News</span>
              </li>
            )}
            {selectedEmails.includes("113917") && (
              <li className="flex gap-2">
                <Icon
                  name="check"
                  className="w-6 h-6 rounded-full p-1 border-2 border-black "
                />
                <span>Community News</span>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NewsletterSignup;
