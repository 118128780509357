import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { Text, Button } from "@atoms";
import Card from "./Card";

dayjs.extend(weekOfYear);

const Items = ({ first, week: w, events }) => {
  const sameMonth =
    dayjs().week(w).startOf("w").month() === dayjs().week(w).endOf("w").month();
  const nextWeekString = `${dayjs()
    .week(w)
    .startOf("w")
    .format("MMMM D")} - ${dayjs()
    .week(w)
    .endOf("w")
    .format(sameMonth ? "D" : "MMMM D")}`;

  return (
    <>
      <Text variant="h6" className="mb-8">
        {first ? "This Week" : nextWeekString}
      </Text>
      {events?.length > 0 && (
        <div className="mt-12 grid grid-cols-1 gap-14 gap-y-20 pb-10 sm:grid-cols-2 sm:pb-20">
          {events?.map(event => {
            const key = `${w}-${event.uid || event.id}-${event.slug}`;
            return <Card key={key} {...event} detailsButton event />;
          })}
        </div>
      )}
      {!events?.length && (
        <Text variant="sm" className="mb-10 sm:mb-20">
          This week&apos;s events are done. View upcoming events below.
        </Text>
      )}
    </>
  );
};

const CalendarList = ({ events, setHasEvents }) => {
  const [toShow, setToShow] = useState(1);
  const thisWeek = dayjs().week();

  // sorted events
  const eventsInWeeks = useMemo(
    () =>
      events.reduce((allEvents, m, i, a) => {
        const week = dayjs(m.start).week();
        const filtered = a
          ?.filter(e => e?.status === "live")
          .filter(e => dayjs(e.start).week() === week);
        const weeksToShowTo = thisWeek + toShow;
        const insideRange = week >= thisWeek && week <= weeksToShowTo;

        if (week === thisWeek || (filtered?.length > 0 && insideRange)) {
          return {
            ...allEvents,
            [week]: filtered,
          };
        }

        return {
          ...allEvents,
        };
      }, {}),
    [toShow]
  );
  const hasEvents = Object.values(eventsInWeeks)?.flat()?.length > 0;

  useEffect(() => setHasEvents(hasEvents), []);

  return (
    <>
      {Object.entries(eventsInWeeks)?.map(([key, e]) => (
        <Items
          key={key}
          events={e}
          week={parseInt(key, 10)}
          first={parseInt(key, 10) === thisWeek}
        />
      ))}

      {hasEvents && toShow <= 4 && (
        <div className="w-full flex justify-center mb-10 sm:mb-20">
          <Button onClick={() => setToShow(toShow + 20)}>Load More</Button>
        </div>
      )}
    </>
  );
};

export default CalendarList;
